import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { identifierActions } from "store/features/identifierSlice";

const usePersistentSession = () => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_ENVIRONMENT_MODE === "dev"
            ? process.env.REACT_APP_BACKEND_API_REFRESH_URL
            : process.env.REACT_APP_BACKEND_API_REFRESH_URL,
          {
            withCredentials: true,
          }
        );

        if (response.data.role === "admin") {
          dispatch(identifierActions.setRole({ role: "admin" }));
          dispatch(identifierActions.setId({ id: response.data.id }));
        }
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading };
};

export default usePersistentSession;
